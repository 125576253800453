import {
  Button,
  ButtonArrow,
  ErrorText,
  GridCol,
  GridRow,
  Main,
  Paragraph,
  Spinner
} from 'govuk-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmationPopUp } from '../../components/ConfirmationPopUp'
import { DownloadBundle } from '../../components/DownloadBundle'
import { ErrorSummaryRetry } from '../../components/ErrorSummaryRetry'
import { Link } from '../../components/Link'
import { ProgressBar } from '../../components/ProgressBar'
import { ProgressBarStep } from '../../components/ProgressBarStep'
import { SuccessPanel } from '../../components/SuccessPanel'
import {
  BundleStatus,
  DossierStatus,
  KeyEventGenerationStatus,
  useGetBundleInformationQuery,
  useGetDossiersDrsUploadStatusQuery,
  useGetDossiersWithDrsDocumentIdentifierQuery
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { useModal } from '../../hooks/useModal'
import { DownloadFile, DrsUploadStatus, Step } from '../../types'
import { getBase64LengthFromByteLength } from '../../utils/fileHelper'
import { formatByteDisplay } from '../../utils/formatByteDisplay'
import { formatDossierType } from '../../utils/formatDossierType'
import styles from './BundleComplete.module.scss'

const DRS_FILE_UPLOAD_LIMIT_BYTES = process.env
  .REACT_APP_DRS_FILE_UPLOAD_LIMIT_IN_BYTES
  ? process.env.REACT_APP_DRS_FILE_UPLOAD_LIMIT_IN_BYTES
  : 26214400
const POLLING_INTERVAL = 5000
const DOSSIER_S3_BUCKET_NAME = process.env.REACT_APP_DOSSIER_S3_BUCKET_NAME
const DOSSIER_KMS_KEY_ID = process.env.REACT_APP_DOSSIER_KMS_KEY_ID
const IS_DRS_UPLOAD_DISABLED =
  process.env.REACT_APP_IS_DRS_UPLOAD_DISABLED === 'true'

/** Complete screen showing progress of upload to DRS for each bundle dossier created (if applicable).*/
export const BundleComplete: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const {
    closeModal: closeFinishBundleModal,
    isModalOpen: isFinishBundleModalOpen
  } = useModal()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()

  const [steps, setSteps] = useState<Step[]>()
  const [downloadFiles, setDownloadFiles] = useState<DownloadFile[]>([])
  const [completedDossierIds, setCompletedDossierIds] = useState<string[]>([])

  const { bundleId } = useParams()

  const [filesUploadDownloadErrors, setFilesUploadDownloadErrors] = useState<
    { targetName: string; text: string }[]
  >([])

  const handleFinishBundleConfirmation = () => {
    closeFinishBundleModal()
    navigate('/')
  }

  const {
    data: dossiersData,
    loading: dossiersLoading,
    error: dossiersError,
    refetch: dossierRefetch
  } = useGetDossiersWithDrsDocumentIdentifierQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    }
  })

  const {
    data: bundleInformationData,
    loading: bundleInformationLoading,
    error: bundleInformationError,
    refetch: bundleInformationRefetch
  } = useGetBundleInformationQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    }
  })

  const {
    data: drsUploadStatusData,
    loading: drsUploadStatusLoading,
    error: drsUploadStatusError,
    startPolling,
    stopPolling,
    refetch: drsUploadStatusRefetch
  } = useGetDossiersDrsUploadStatusQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    },
    fetchPolicy: 'cache-and-network'
  })

  const dossiersExceedDrsUploadLimit = useMemo(() => {
    return dossiersData
      ? dossiersData.bundle.dossiers.some(({ fileSize, status }) => {
          return (
            status === DossierStatus.Completed &&
            fileSize &&
            (fileSize > DRS_FILE_UPLOAD_LIMIT_BYTES ||
              getBase64LengthFromByteLength(fileSize) >
                DRS_FILE_UPLOAD_LIMIT_BYTES)
          )
        })
      : false
  }, [dossiersData])

  /** Redirect user to main editing screen if bundle status is in progress.
   *  Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (
      dossiersData?.bundle?.status === BundleStatus.InProgress &&
      !dossiersLoading
    ) {
      navigate(`/bundles/${bundleId}`)
    }
  }, [bundleId, dossiersData?.bundle?.status, dossiersLoading, navigate])

  // Get list of documents to download when dossiersData is updated
  useEffect(() => {
    const dossiersToDownload: DownloadFile[] = []

    if (dossiersData) {
      // Get all completed dossiers with valid fileS3ObjectKey
      const completedDossiers = dossiersData.bundle.dossiers.filter(
        (dossier) =>
          dossier.status === DossierStatus.Completed &&
          dossier.fileS3ObjectKey !== null &&
          dossier.fileS3ObjectKey !== undefined
      )

      if (completedDossiers.length > 0) {
        if (!dossiersExceedDrsUploadLimit) {
          // Store completed dossier Ids for DRS polling
          setCompletedDossierIds(completedDossiers.map((dossier) => dossier.id))
        }

        // Extract all completed dossiers into list of documents to download
        completedDossiers.forEach((dossier) => {
          if (dossier.fileS3ObjectKey) {
            // Create new download document
            const dossierFile: DownloadFile = {
              fileName: `${formatDossierType(dossier.type)} - ${
                dossiersData.bundle?.customerName
              }.pdf`,
              s3ObjectKey: dossier?.fileS3ObjectKey,
              bucketName: DOSSIER_S3_BUCKET_NAME,
              kmsKeyArn: DOSSIER_KMS_KEY_ID
            }
            // Add document to download collection
            dossiersToDownload.push(dossierFile)
          }
        })

        // Push Scheduler Evidence document to list of documents to download
        const schedulerEvidenceFile: DownloadFile = {
          fileName: `Scheduler Evidence - ${dossiersData.bundle?.customerName}.txt`,
          s3ObjectKey: `bundles/${bundleId}/Schedule_Details.txt`,
          bucketName: DOSSIER_S3_BUCKET_NAME
        }
        dossiersToDownload.push(schedulerEvidenceFile)
        if (
          bundleInformationData?.bundle.keyEventStatus ===
            KeyEventGenerationStatus.Completed &&
          bundleInformationData?.bundle.keyEventsPdfFileS3ObjectKey &&
          bundleInformationData?.bundle.keyEventsTxtFileS3ObjectKey
        ) {
          dossiersToDownload.push({
            fileName: `Key Events - ${dossiersData.bundle?.customerName}.pdf`,
            s3ObjectKey:
              bundleInformationData?.bundle.keyEventsPdfFileS3ObjectKey,
            bucketName: DOSSIER_S3_BUCKET_NAME,
            kmsKeyArn: DOSSIER_KMS_KEY_ID
          })
          dossiersToDownload.push({
            fileName: `Key Events - ${dossiersData.bundle?.customerName}.txt`,
            s3ObjectKey:
              bundleInformationData?.bundle.keyEventsTxtFileS3ObjectKey,
            bucketName: DOSSIER_S3_BUCKET_NAME,
            kmsKeyArn: DOSSIER_KMS_KEY_ID
          })
        }
      }
    }
    setDownloadFiles(dossiersToDownload)
  }, [
    dossiersData,
    bundleId,
    dossiersExceedDrsUploadLimit,
    bundleInformationData
  ])

  // Set DRS progress display when drsUploadStatusData is updated
  useEffect(() => {
    const drsUploadSteps: Step[] = []
    const dossierError: { targetName: string; text: string }[] = []

    if (
      !IS_DRS_UPLOAD_DISABLED &&
      dossiersData?.bundle.drsDocumentIdentifierType !== undefined &&
      dossiersData?.bundle.drsDocumentIdentifierType !== null &&
      drsUploadStatusData &&
      !dossiersExceedDrsUploadLimit
    ) {
      const errorDossiers: string[] = []

      // Extract upload status for each drs upload dossier
      drsUploadStatusData.bundle.dossiers.forEach((dossier, index) => {
        if (completedDossierIds.includes(dossier.id)) {
          // Get upload status
          const uploadStatus = dossier.hasUploadingFailed
            ? DrsUploadStatus.ERROR
            : dossier.isUploaded
            ? DrsUploadStatus.COMPLETE
            : DrsUploadStatus.IN_PROGRESS

          // Add upload status to drs upload display steps
          drsUploadSteps.push({
            index: index,
            title: dossier.type,
            status: uploadStatus
          })

          if (dossier.hasUploadingFailed) {
            errorDossiers.push(formatDossierType(dossier.type))
          }
        }
      })

      const inProgressDossiers = drsUploadSteps.filter(
        (dossier) => dossier.status === DrsUploadStatus.IN_PROGRESS
      )

      if (inProgressDossiers && inProgressDossiers.length > 0) {
        startPolling(POLLING_INTERVAL)
      } else {
        stopPolling()
      }

      if (errorDossiers.length > 0) {
        const formatter = new Intl.ListFormat('en-GB', {
          style: 'long',
          type: 'conjunction'
        })

        dossierError.push({
          targetName: 'filesUploadDownloadErrors',
          text: `Sorry, there is a problem uploading ${formatter.format(
            errorDossiers
          )} bundle to DRS. Please manually upload to DRS.`
        })
      }
    } else {
      stopPolling()
    }

    setSteps(drsUploadSteps)
    setFilesUploadDownloadErrors(dossierError)
  }, [
    drsUploadStatusData,
    startPolling,
    stopPolling,
    completedDossierIds,
    dossiersData?.bundle.drsDocumentIdentifierType,
    setFilesUploadDownloadErrors,
    dossiersExceedDrsUploadLimit
  ])

  useEffect(() => {
    if (dossiersError) {
      addGraphQlError('dossiersError', 'Could not find Dossier')
    }
  }, [dossiersError, addGraphQlError])

  useEffect(() => {
    if (drsUploadStatusError) {
      addGraphQlError('drsUploadStatusError', 'Could not check upload status')
    }
  }, [drsUploadStatusError, addGraphQlError])

  const handleFilesDownloadError = useCallback(
    (error: string) => {
      if (
        error &&
        !filesUploadDownloadErrors.find(
          ({ text }) =>
            text === 'Sorry, there is a problem downloading your bundle.'
        )
      ) {
        setFilesUploadDownloadErrors((e) => [
          {
            targetName: 'filesUploadDownloadErrors',
            text: 'Sorry, there is a problem downloading your bundle.'
          },
          ...e
        ])
      }
    },
    [filesUploadDownloadErrors, setFilesUploadDownloadErrors]
  )

  const handleRetryClick = useCallback(() => {
    if (dossiersError) {
      dossierRefetch({ bundleId: bundleId === undefined ? '' : bundleId })
    }
    if (drsUploadStatusError) {
      drsUploadStatusRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
    if (bundleInformationError) {
      bundleInformationRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
  }, [
    bundleId,
    dossiersError,
    dossierRefetch,
    drsUploadStatusError,
    drsUploadStatusRefetch,
    bundleInformationError,
    bundleInformationRefetch
  ])

  if (dossiersLoading || drsUploadStatusLoading || bundleInformationLoading)
    return (
      <Spinner
        height="50px"
        width="50px"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginTop: '-25px',
          marginLeft: '-25px'
        }}
      />
    )

  return (
    <Main>
      {graphQlErrors.length > 0 && (
        <>
          <ErrorSummaryRetry
            heading="There was a problem with the service"
            errors={graphQlErrors}
            onHandleErrorClick={handleRetryClick}
          />
        </>
      )}
      <br />
      <br />
      {graphQlErrors.length === 0 && filesUploadDownloadErrors.length > 0 && (
        <>
          <ErrorSummaryRetry
            includeBorder={true}
            heading="There was a problem with the service"
            errors={filesUploadDownloadErrors}
          />
        </>
      )}
      <br />
      <br />
      <div role={'region'} aria-labelledby={'drs-upload-status'}>
        <div id={'drs-upload-status'}>
          <SuccessPanel title="Bundle completed">
            {!IS_DRS_UPLOAD_DISABLED &&
              !dossiersExceedDrsUploadLimit &&
              dossiersData?.bundle?.drsDocumentIdentifierType &&
              'Your bundle is being uploaded to DRS.'}
          </SuccessPanel>
        </div>
      </div>
      <br />
      {!IS_DRS_UPLOAD_DISABLED &&
        dossiersData?.bundle?.drsDocumentIdentifierType &&
        dossiersData.bundle.dossiers &&
        (dossiersExceedDrsUploadLimit ? (
          <>
            <br />
            <ErrorText>
              {`Unable to upload the bundle to DRS, file size is above ${formatByteDisplay(
                DRS_FILE_UPLOAD_LIMIT_BYTES
              )}. Please download the bundle and upload manually.`}
            </ErrorText>
            <br />
          </>
        ) : (
          <>
            <div role={'region'} aria-label={'progress'}>
              <ProgressBar>
                {steps &&
                  steps.map(({ index, title, status }) => (
                    <ProgressBarStep
                      key={index}
                      title={formatDossierType(title)}
                      status={status}
                    />
                  ))}
              </ProgressBar>
            </div>
            <br />
            <br />
          </>
        ))}
      <div role={'region'} aria-labelledby={'download-bundle'}>
        <div id={'download-bundle'}>
          <GridRow>
            <GridCol>
              <Paragraph>
                You can download the bundle onto your computer by clicking the
                button below.
              </Paragraph>
              <p className={styles.completedBundleText}>
                The completed bundle can be downloaded from the{' '}
                <Link to={'/'}>dashboard</Link> for up to 48 hours after you’ve
                completed it.
              </p>
            </GridCol>
            <ConfirmationPopUp
              title="Are you sure you want to finish?"
              confirmationButtonText="Finish bundle"
              confirmationMessageText={[
                'Note that after finishing you cannot download the bundle. Make sure you downloaded the bundle before clicking on finish.'
              ]}
              isOpen={isFinishBundleModalOpen}
              onCloseModal={closeFinishBundleModal}
              onConfirmationButtonClick={handleFinishBundleConfirmation}
            />
          </GridRow>
        </div>
      </div>
      <br />
      <br />
      <GridRow>
        <GridCol setWidth="74%">
          <DownloadBundle
            fileName={
              dossiersData?.bundle?.customerName
                ? `${dossiersData.bundle?.customerName} Bundles`
                : 'Bundles'
            }
            documents={downloadFiles}
            onFilesDownloadError={(error) => handleFilesDownloadError(error)}
          />
        </GridCol>
        <GridCol setWidth="26%">
          <div className={styles.createNewBundle}>
            <Button
              icon={<ButtonArrow />}
              onClick={() => navigate('/bundles/create')}
            >
              Create a new bundle
            </Button>
          </div>
        </GridCol>
      </GridRow>
    </Main>
  )
}
