import {
  Button,
  ButtonArrow,
  GridCol,
  GridRow,
  Heading,
  LeadParagraph,
  LoadingBox,
  Main,
  Paragraph
} from 'govuk-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { CompleteBundleTable } from '../../components/CompleteBundleTable'
import { ErrorSummaryRetry } from '../../components/ErrorSummaryRetry'
import { ModalDialogue } from '../../components/ModalDialogue'
import { SavedBundleTable } from '../../components/SavedBundleTable'
import { useGetCurrentUserQuery } from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'

import styles from './Dashboard.module.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WhiteHeading = styled(Heading)<any>`
  color: #fff;
  margin-bottom: 30px;
`

const WhiteLeadParagraph = styled(LeadParagraph)`
  color: #fff;
`

const WhiteButton = styled(Button).attrs(() => ({
  buttonColour: '#ffffff',
  buttonTextColour: '#1d70b8',
  buttonHoverColour: '#e8f1f8',
  buttonShadowColour: '#144e81'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}))<any>`
  margin-bottom: 0;
`

/** Dashboard page displayed on index page of application .*/
export const Dashboard: React.FunctionComponent = () => {
  const [isCompletedBundleDataLoading, setIsCompletedBundleDataLoading] =
    useState(false)
  const [isSavedBundlesDataLoading, setIsSavedBundlesDataLoading] =
    useState(false)
  const navigate = useNavigate()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()
  const {
    data: currentUserData,
    error: currentUserError,
    refetch: currentUserRefetch
  } = useGetCurrentUserQuery()

  const handleCreateNewBundleClick = () => {
    navigate('/bundles/create')
  }

  const handleContinueProfileClick = () => {
    navigate('/profile')
  }

  const handleRetryClick = useCallback(() => {
    if (currentUserError) {
      currentUserRefetch()
    }
  }, [currentUserError, currentUserRefetch])

  useEffect(() => {
    if (currentUserError) {
      addGraphQlError('currentUserError', 'Could not find user details')
    }
  }, [currentUserError, addGraphQlError])

  return (
    <>
      {graphQlErrors.length > 0 ? (
        <Main>
          <ErrorSummaryRetry
            heading="There was a problem with the service"
            errors={graphQlErrors}
            onHandleErrorClick={handleRetryClick}
            includeBorder={false}
          />
        </Main>
      ) : (
        <>
          <div className={styles.container}>
            <Main>
              <GridRow>
                <GridCol setWidth="two-thirds">
                  <WhiteHeading size="XLARGE">
                    Quickly and easily create bundles of evidence
                  </WhiteHeading>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol setWidth="three-quarters">
                  <WhiteLeadParagraph>
                    Use Bundle Builder to create bundles of evidence with the
                    option to redact sensitive information and mark pages as
                    harmful.
                  </WhiteLeadParagraph>
                  <WhiteButton
                    start
                    title="Create bundle"
                    onClick={handleCreateNewBundleClick}
                    icon={<ButtonArrow />}
                  >
                    Create bundle
                  </WhiteButton>
                </GridCol>
              </GridRow>
            </Main>
          </div>
          <Main>
            <LoadingBox
              loading={
                isCompletedBundleDataLoading || isSavedBundlesDataLoading
              }
            >
              <SavedBundleTable
                handleSavedBundleDataLoading={setIsSavedBundlesDataLoading}
              />
              <CompleteBundleTable
                handleCompleteBundleDataLoading={
                  setIsCompletedBundleDataLoading
                }
              />
            </LoadingBox>
          </Main>
          {currentUserData &&
            (currentUserData.getCurrentUser?.office === null ||
              currentUserData.getCurrentUser?.office === undefined) && (
              <ModalDialogue
                title={'Please complete your profile to continue.'}
                isOpen={true}
                isDisplayCloseIcon={false}
              >
                <Paragraph>
                  You must have a complete profile before you can use Bundle
                  Builder.
                </Paragraph>
                <Button
                  margin={1}
                  type="button"
                  onClick={handleContinueProfileClick}
                >
                  Continue to Profile
                </Button>
              </ModalDialogue>
            )}
          <Outlet />
        </>
      )}
    </>
  )
}
