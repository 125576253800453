import { GridCol, GridRow, Heading, Paragraph, Table } from 'govuk-react'
import React, { useCallback, useEffect } from 'react'
import {
  BundleStatus,
  KeyEventGenerationStatus,
  useGetCompletedBundlesQuery
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { CompleteBundleTableRow } from '../CompleteBundleTableRow'
import { ErrorSummaryRetry } from '../ErrorSummaryRetry'
import { TableHeader } from '../TableHeader'
import styles from './CompleteBundleTable.module.scss'

export interface CompleteBundleTableProps {
  /** Handle change of bundle data loading state. */
  handleCompleteBundleDataLoading: React.Dispatch<React.SetStateAction<boolean>>
}

/** Table displaying completed bundles. Each row shows the customer name, business area, appeal type and a download link of a completed bundle.
 * Bundle can be download from the table with the click on download link.
 * Will display error if there is issue while downloading documents*/
export const CompleteBundleTable: React.FunctionComponent<
  CompleteBundleTableProps
> = ({ handleCompleteBundleDataLoading }) => {
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()
  const {
    graphQlErrors: documentDownloadErrors,
    addGraphQlError: addDocumentDownloadError
  } = useGraphQlErrors()
  const {
    data: completeBundlesData,
    loading: completeBundlesLoading,
    error: completeBundlesError,
    refetch: completeBundlesRefetch
  } = useGetCompletedBundlesQuery({
    variables: {
      bundlesStatus: BundleStatus.Complete
    },
    onError: () => {
      addGraphQlError('completeBundlesError', 'Could not load complete bundle')
    },
    fetchPolicy: 'network-only'
  })

  // Add download error to collection if not already captured
  const handleDocumentDownloadError = useCallback(
    (error: string, customerName: string) => {
      if (!documentDownloadErrors.find(({ text }) => text === customerName)) {
        addDocumentDownloadError(error, customerName)
      }
    },
    [documentDownloadErrors, addDocumentDownloadError]
  )

  // Retry completed bundles query
  const handleRetryClick = useCallback(() => {
    if (completeBundlesError) {
      completeBundlesRefetch()
    }
  }, [completeBundlesError, completeBundlesRefetch])

  // Set bundle loading status prop when loading response changes
  useEffect(() => {
    handleCompleteBundleDataLoading(completeBundlesLoading)
  }, [completeBundlesLoading, handleCompleteBundleDataLoading])

  return (
    <>
      {graphQlErrors.length > 0 ? (
        <ErrorSummaryRetry
          heading="There was a problem with the service"
          errors={graphQlErrors}
          onHandleErrorClick={handleRetryClick}
          includeBorder={false}
        />
      ) : documentDownloadErrors.length > 0 ? (
        <div className={styles.errorSummary}>
          <Heading size="MEDIUM">There is a problem</Heading>
          {documentDownloadErrors.map((error) => {
            return (
              <Paragraph key={error.text}>
                {`There is an issue downloading the bundle for customer ${error.text}.`}
              </Paragraph>
            )
          })}
          <Paragraph>Try again later.</Paragraph>
          <Paragraph>
            If the issue persists, [contact the IAG Live Service
            Helpline](https://dwp.service-now.com/itsupport).
          </Paragraph>
        </div>
      ) : (
        <div className={styles.container} role={'region'}>
          {!completeBundlesLoading &&
            completeBundlesData?.bundles &&
            completeBundlesData.bundles.length > 0 && (
              <>
                <GridRow>
                  <GridCol setWidth="full">
                    <Heading size={'MEDIUM'}>Completed Bundles</Heading>
                    <Paragraph>
                      Completed bundles are available to download for 48 hours
                      after the bundle was created.
                    </Paragraph>
                  </GridCol>
                </GridRow>
                <Table
                  caption={'Completed Bundles'}
                  head={
                    <TableHeader
                      columnNames={[
                        'Customer',
                        'Business Area',
                        'Appeal Type',
                        'Download'
                      ]}
                    />
                  }
                  body={completeBundlesData.bundles.map((bundle, index) => (
                    <CompleteBundleTableRow
                      key={bundle?.id ? bundle.id : index}
                      id={bundle?.id ? bundle.id : ''}
                      name={bundle?.customerName ? bundle.customerName : ''}
                      businessArea={
                        bundle?.businessArea.name
                          ? bundle.businessArea.name
                          : ''
                      }
                      appealType={
                        bundle?.appealType?.name ? bundle?.appealType.name : ''
                      }
                      completedDossiers={
                        bundle?.dossiers ? bundle.dossiers : []
                      }
                      onDocumentDownloadError={(error, customerName) =>
                        handleDocumentDownloadError(error, customerName)
                      }
                      keyEventsPdfFileS3ObjectKey={
                        bundle?.keyEventStatus ===
                          KeyEventGenerationStatus.Completed &&
                        bundle?.keyEventsPdfFileS3ObjectKey
                          ? bundle.keyEventsPdfFileS3ObjectKey
                          : ''
                      }
                      keyEventsTxtFileS3ObjectKey={
                        bundle?.keyEventStatus ===
                          KeyEventGenerationStatus.Completed &&
                        bundle?.keyEventsTxtFileS3ObjectKey
                          ? bundle.keyEventsTxtFileS3ObjectKey
                          : ''
                      }
                    />
                  ))}
                />
              </>
            )}
        </div>
      )}
    </>
  )
}
