import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DragUpdate,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import {
  DocumentDateType,
  DocumentStatus,
  namedOperations,
  useGetBundleDocumentsByBundleIdQuery,
  useMoveDocumentAfterMutation
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { BundleDocument } from '../../types'
import { DocumentListItem } from '../DocumentListItem'
import { ErrorRetry } from '../ErrorRetry'
import styles from './DocumentList.module.scss'

const POLLING_INTERVAL = 5000
const LARGE_DOCUMENT_THRESHOLD_IN_BYTES = process.env
  .REACT_APP_LARGE_DOCUMENT_THRESHOLD_IN_BYTES
  ? process.env.REACT_APP_LARGE_DOCUMENT_THRESHOLD_IN_BYTES
  : 15000000

interface BundleDocumentListItem extends BundleDocument {
  isFromModal?: boolean
}

export interface DocumentListProps {
  /** Handle document selection. */
  handleSelectDocument: (documentId: string) => void
  /** Is the bundle size greater than the limit. */
  isBundleSizeLimitBreached: boolean
}

/** Displays list of document items. The list can be sorted by dragging and dropping. .*/
export const DocumentList: React.FunctionComponent<DocumentListProps> = ({
  handleSelectDocument,
  isBundleSizeLimitBreached
}) => {
  const { bundleId, evidenceId } = useParams()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()

  const [bundleDocumentsList, setBundleDocumentsList] = useState<
    BundleDocumentListItem[]
  >([])
  const [documentCount, setDocumentCount] = useState(0)
  const [enableUiBlock, setEnableUiBlock] = useState(false)

  const {
    data: bundleDocumentsData,
    error: bundleDocumentsError,
    refetch: bundleDocumentsRefetch,
    startPolling,
    stopPolling
  } = useGetBundleDocumentsByBundleIdQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    },
    fetchPolicy: 'cache-and-network'
  })

  const [moveDocumentAfter] = useMoveDocumentAfterMutation({
    refetchQueries: [namedOperations.Query.GetBundleDocumentsByBundleId],
    onCompleted: () => {
      setEnableUiBlock(false)
    },
    onError: () => {
      setEnableUiBlock(false)
    }
  })

  /**
   * Add documentId to prop function to pass up to parent component.
   * Set documentId to component state for conditional styling of JSX element.
   */
  const selectDocument = (documentId: string) => {
    handleSelectDocument(documentId)
  }

  // Store end of list element to automate scroll to last items added
  const endOfList = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (bundleDocumentsData?.bundle?.documents) {
      const documentListFromQuery = bundleDocumentsData?.bundle?.documents.map(
        (document) => ({
          ...document,
          isFromModal: false
        })
      )

      setBundleDocumentsList(documentListFromQuery)

      const loadingDocuments = documentListFromQuery.filter(
        (document) => document.status === DocumentStatus.Loading
      )

      if (loadingDocuments && loadingDocuments.length > 0) {
        startPolling(POLLING_INTERVAL)
      }
    }
    return () => {
      stopPolling()
    }
  }, [bundleDocumentsData, startPolling, stopPolling])

  // Activate scroll to end of list when new documents are added
  useEffect(() => {
    if (bundleDocumentsList.length > documentCount) {
      if (documentCount > 0) {
        // Flag new items from modal for styling
        for (let i = documentCount; i < bundleDocumentsList.length; i++) {
          if (bundleDocumentsList[i].status === DocumentStatus.Loading) {
            bundleDocumentsList[i].isFromModal = true
          }
        }
        endOfList.current?.scrollIntoView({ behavior: 'smooth' })
      }
      setDocumentCount(bundleDocumentsList.length)
    }
  }, [bundleDocumentsList, documentCount])

  useEffect(() => {
    if (bundleDocumentsError) {
      addGraphQlError(
        'bundleDocumentsError',
        'Could not retrieve bundled documents'
      )
    }
  }, [bundleDocumentsError, addGraphQlError])

  const handleRetryClick = useCallback(() => {
    if (bundleDocumentsError) {
      bundleDocumentsRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
  }, [bundleId, bundleDocumentsError, bundleDocumentsRefetch])

  // Handle drag end activites
  const onDragEnd: (result: DropResult) => void = async (
    result: DropResult
  ) => {
    // Extract source and destination from dragging activity
    const { destination, source } = result

    // If the item is dropped outside of the list, or the source and target have the same index, do nothing
    if (
      !destination ||
      destination.droppableId !== 'documentList' ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    // Store copy of document list for manipulation
    const currentList = bundleDocumentsList.map((document) => {
      return { ...document, fromModal: false }
    })

    // Get selected document item by index, and splice out of current list
    const documentToMove = currentList[source.index]

    currentList.splice(source.index, 1)

    // Splice selected document item back into list
    currentList.splice(destination.index, 0, documentToMove)

    // Update reordered component document list to prevent flashing before query reloads
    setBundleDocumentsList(currentList)

    // Get document to move after
    let documentToMoveAfter: BundleDocument | undefined
    if (destination.index === 0) {
      documentToMoveAfter = undefined
    } else if (source.index > destination.index) {
      documentToMoveAfter = bundleDocumentsList[destination.index - 1]
    } else {
      documentToMoveAfter = bundleDocumentsList[destination.index]
    }

    // Call mutation
    moveDocumentAfter({
      variables: {
        input: {
          bundleId: bundleId === undefined ? '' : bundleId,
          documentToMove: documentToMove.id as string,
          documentToMoveAfter: documentToMoveAfter?.id as string | undefined
        }
      }
    })
  }

  const onDragUpdate = (initial: DragUpdate) => {
    /**
     *  If list item is dropped outside of the list, then unblock UI
     */
    if (!initial.destination) {
      setEnableUiBlock(false)
      return
    }

    /**
     *  If list item is dropped in different position of list, block the UI, else if the same position, unblock UI
     */
    initial.destination?.index !== initial.source.index
      ? setEnableUiBlock(true)
      : setEnableUiBlock(false)
  }

  return (
    <div
      className={`${styles.container} ${
        isBundleSizeLimitBreached
          ? styles.containerHeightWithError
          : styles.containerHeight
      }`}
      data-testid={'document-list'}
    >
      {graphQlErrors.length > 0 && (
        <>
          <ErrorRetry
            heading="There was a problem with the service"
            onHandleErrorClick={handleRetryClick}
          />
        </>
      )}
      <div className={enableUiBlock ? styles.disabled : ''}>
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <Droppable droppableId="documentList">
            {(provided) => (
              <div
                className="droppable"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {bundleDocumentsList.map((bundleDocument, idx) => {
                  return (
                    <Draggable
                      key={bundleDocument.id}
                      draggableId={bundleDocument.id}
                      index={idx}
                      disableInteractiveElementBlocking={enableUiBlock}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={`incomplete ${
                            snapshot.isDragging ? 'dragging' : ''
                          }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...{
                            ...provided.dragHandleProps,
                            tabIndex: -1
                          }}
                        >
                          <div
                            className={styles.documentListItemContainer}
                            key={bundleDocument.id}
                          >
                            <DocumentListItem
                              onDocumentItemClick={() =>
                                selectDocument(bundleDocument.id)
                              }
                              documentName={
                                bundleDocument.name ? bundleDocument.name : ''
                              }
                              documentStatus={
                                bundleDocument.status
                                  ? bundleDocument.status
                                  : DocumentStatus.NotReviewed
                              }
                              documentDate={
                                bundleDocument.date &&
                                bundleDocument.dateType ===
                                  DocumentDateType.Dated
                                  ? bundleDocument.date
                                  : ''
                              }
                              isSelected={bundleDocument.id === evidenceId}
                              isDragged={snapshot.isDragging}
                              isDropped={snapshot.isDropAnimating}
                              isLoading={false}
                              isCorrupt={
                                bundleDocument.status === DocumentStatus.Corrupt
                              }
                              isInvalid={
                                bundleDocument.status === DocumentStatus.Invalid
                              }
                              isError={
                                bundleDocument.status === DocumentStatus.Error
                              }
                              isFromModal={bundleDocument.isFromModal}
                              isLargeFile={
                                bundleDocument.optimisedFileSizeInBytes &&
                                bundleDocument.optimisedFileSizeInBytes >
                                  LARGE_DOCUMENT_THRESHOLD_IN_BYTES
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                <div ref={endOfList} />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}
