import { BiErrorCircle as UploadError } from '@react-icons/all-files/bi/BiErrorCircle'
import { Spinner, Table } from 'govuk-react'
import React, { useEffect } from 'react'
import { DossierStatus, DossierType } from '../../graphql/generated/schema'
import { useDownloadZipFile } from '../../hooks/useDownloadBundles'
import { DownloadFile } from '../../types'
import { formatDossierType } from '../../utils/formatDossierType'
import { LinkButton } from '../LinkButton'
import styles from './CompleteBundleTableRow.module.scss'

export type CompletedDossier = {
  __typename?: 'Dossier' | undefined
  id: string
  type: DossierType
  fileS3ObjectKey?: string | null | undefined
  status: DossierStatus
}

const DOSSIER_S3_BUCKET_NAME = process.env.REACT_APP_DOSSIER_S3_BUCKET_NAME
const DOSSIER_KMS_KEY_ID = process.env.REACT_APP_DOSSIER_KMS_KEY_ID

export interface CompleteBundleTableRowProps {
  /** The Id of the bundle */
  id: string
  /** The name of the bundle */
  name: string
  /** The business area of the bundle */
  businessArea: string
  /** The appeal type of the bundle */
  appealType: string
  /** Collection of completed dossiers to download */
  completedDossiers: CompletedDossier[]
  /** Function to call if error occurs while downloading the zip file */
  onDocumentDownloadError: (error: string, customerName: string) => void
  /** S3 object of the key events pdf file */
  keyEventsPdfFileS3ObjectKey: string
  /** S3 object of the key events pdf file */
  keyEventsTxtFileS3ObjectKey: string
}

/** Table row of a completed bundle. Shows the customer name, business area, appeal type and a download link of the completed bundle.
 * Bundle can be download from the table with the click on download link. */
export const CompleteBundleTableRow: React.FunctionComponent<
  CompleteBundleTableRowProps
> = ({
  id,
  name,
  businessArea,
  appealType,
  completedDossiers,
  onDocumentDownloadError,
  keyEventsPdfFileS3ObjectKey,
  keyEventsTxtFileS3ObjectKey
}) => {
  const { download, isLoading, error } = useDownloadZipFile()

  const handleOnClickDownload = async () => {
    await download(
      getDownloadDocuments(completedDossiers, name, id),
      `${name} Bundles`
    )
  }

  const getDownloadDocuments = (
    dossiers: CompletedDossier[],
    customerName: string,
    bundleId: string
  ) => {
    const filesToDownload: DownloadFile[] = []
    dossiers.forEach((dossier) => {
      if (
        dossier.fileS3ObjectKey !== null &&
        dossier.fileS3ObjectKey !== undefined &&
        dossier.status === DossierStatus.Completed
      ) {
        filesToDownload.push({
          fileName: `${formatDossierType(dossier.type)} - ${customerName}.pdf`,
          s3ObjectKey: dossier.fileS3ObjectKey,
          bucketName: DOSSIER_S3_BUCKET_NAME,
          kmsKeyArn: DOSSIER_KMS_KEY_ID
        })
      }
    })

    // Add Schedule_Details file for download document
    if (filesToDownload.length > 0) {
      filesToDownload.push({
        fileName: `Scheduler Evidence - ${customerName}.txt`,
        s3ObjectKey: `bundles/${bundleId}/Schedule_Details.txt`,
        bucketName: DOSSIER_S3_BUCKET_NAME
      })
    }

    if (keyEventsPdfFileS3ObjectKey) {
      filesToDownload.push({
        fileName: `Key Events - ${customerName}.pdf`,
        s3ObjectKey: keyEventsPdfFileS3ObjectKey,
        bucketName: DOSSIER_S3_BUCKET_NAME,
        kmsKeyArn: DOSSIER_KMS_KEY_ID
      })
    }
    if (keyEventsTxtFileS3ObjectKey) {
      filesToDownload.push({
        fileName: `Key Events - ${customerName}.txt`,
        s3ObjectKey: keyEventsTxtFileS3ObjectKey,
        bucketName: DOSSIER_S3_BUCKET_NAME,
        kmsKeyArn: DOSSIER_KMS_KEY_ID
      })
    }
    return filesToDownload
  }

  useEffect(() => {
    if (error !== null) {
      onDocumentDownloadError(error, name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{businessArea}</Table.Cell>
      <Table.Cell>{appealType}</Table.Cell>
      <Table.Cell>
        {isLoading ? (
          <Spinner
            fill="#1D70B8"
            height="25px"
            title="Downloading"
            width="25px"
          />
        ) : (
          <>
            <LinkButton onClick={handleOnClickDownload}>Download</LinkButton>
            {error !== null && <UploadError className={styles.errorIcon} />}
          </>
        )}
      </Table.Cell>
    </Table.Row>
  )
}
