import { Main, Spinner } from 'govuk-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BundleSetupForm } from '../../components/BundleSetupForm/BundleSetupForm'
import { ConfirmationPopUp } from '../../components/ConfirmationPopUp'
import { GatherDrsDocumentsForm } from '../../components/GatherDrsDocumentsForm'
import { GatherLocalDocumentsForm } from '../../components/GatherLocalDocumentsForm'
import {
  BundleStatus,
  DrsDocumentIdentifierType,
  useCreateBundleMutation,
  useDeleteBundleMutation,
  useGetSavedBundlesQuery
} from '../../graphql/generated/schema'
import { useModal } from '../../hooks/useModal'
import { CreateBundleFormData } from '../../types'
import styles from './CreateBundle.module.scss'

export const INITIAL_BUSINESS_AREA_VALUE = 'Choose business area'

/** Page route allowing user to complete the initial bundle setup form.*/
export const CreateBundle: React.FunctionComponent = () => {
  const navigate = useNavigate()

  const [data, setData] = useState<CreateBundleFormData>({
    customerName: '',
    businessArea: INITIAL_BUSINESS_AREA_VALUE,
    appealType: '',
    isDrsEnabled: true,
    drsRequired: '',
    localUploadRequired: '',
    drsSearchIdentifier: null,
    nationalInsuranceNumber: '',
    customerReferenceNumber: '',
    claimReferenceNumber: '',
    localDocuments: []
  })

  const [currentStep, setCurrentStep] = useState<number>(0)
  const {
    isModalOpen: isCreateBundleModalOpen,
    openModal: openCreateBundleModal,
    closeModal: closeCreateBundleModal
  } = useModal()

  const {
    data: savedBundleData,
    error: saveBundleError,
    loading: savedBundleLoading
  } = useGetSavedBundlesQuery({
    variables: {
      bundlesStatus: BundleStatus.InProgress
    },
    fetchPolicy: 'network-only'
  })

  const [
    createBundleMutation,
    {
      data: createBundleData,
      error: createBundleError,
      loading: createBundleLoading
    }
  ] = useCreateBundleMutation()

  const submitFormData = (data: CreateBundleFormData) => {
    const drsSearchValue = (
      drsSearchType: DrsDocumentIdentifierType | null
    ): string | null => {
      switch (drsSearchType) {
        case DrsDocumentIdentifierType.Nino:
          return data.nationalInsuranceNumber.toUpperCase()
        case DrsDocumentIdentifierType.ClaimReferenceNumber:
          return data.claimReferenceNumber.toUpperCase()
        case DrsDocumentIdentifierType.CustomerReferenceNumber:
          return data.customerReferenceNumber.toUpperCase()
        default:
          return null
      }
    }

    const input = {
      customerName: data.customerName,
      businessAreaId: data.businessArea,
      appealTypeId: data.appealType ? data.appealType : null,
      drsDocumentIdentifierType:
        data.drsRequired === 'Yes' ? data.drsSearchIdentifier : null,
      drsDocumentIdentifierValue:
        data.drsRequired === 'Yes'
          ? drsSearchValue(data.drsSearchIdentifier)
          : null,
      localDocuments:
        data.localDocuments !== null
          ? data.localDocuments.map(
              ({ name, temporaryFileS3ObjectKey, isUploaded }) => {
                if (isUploaded) {
                  return {
                    name,
                    temporaryFileS3ObjectKey
                  }
                } else {
                  return null
                }
              }
            )
          : null
    }

    createBundleMutation({
      variables: {
        input
      }
    })
  }

  useEffect(() => {
    if (createBundleData?.createBundle?.id && !createBundleLoading) {
      navigate(`/bundles/${createBundleData?.createBundle?.id}`)
    }
  }, [createBundleData?.createBundle?.id, createBundleLoading, navigate])

  if (createBundleError) {
    throw new Error(JSON.stringify(createBundleError, null, 2))
  }

  const handleNextStep = (
    newData: CreateBundleFormData,
    finalStep?: boolean,
    currentStep?: number
  ) => {
    setData((prev) => ({ ...prev, ...newData }))

    if (finalStep) {
      submitFormData(newData)
      return
    }

    let stepIncrement = 1
    if (currentStep === 0 && newData.drsRequired === 'No') {
      stepIncrement = 2
    }

    setCurrentStep((prev) => prev + stepIncrement)
  }

  const handlePreviousStep = (
    newData: CreateBundleFormData,
    currentStep?: number
  ) => {
    setData((prev) => ({ ...prev, ...newData }))

    let stepDecrement = 1
    if (currentStep === 2 && newData.drsRequired === 'No') {
      stepDecrement = 2
    }

    setCurrentStep((prev) => prev - stepDecrement)
  }

  const onCloseCreateBundleModal = () => {
    closeCreateBundleModal()
    navigate('/')
  }

  useEffect(() => {
    if (
      !savedBundleLoading &&
      savedBundleData?.bundles &&
      savedBundleData?.bundles?.length > 0
    ) {
      openCreateBundleModal()
    }
  }, [savedBundleData, savedBundleLoading, openCreateBundleModal])

  const [
    deleteBundleMutation,
    {
      data: deleteBundleData,
      loading: deleteBundleLoading,
      error: deleteBundleError
    }
  ] = useDeleteBundleMutation()

  const openCreateBundle = (
    bundles?: Array<{
      __typename?: 'Bundle'
      id: string
      customerName: string
      createdAt: string
      businessArea: { __typename?: 'BusinessArea'; name: string }
      appealType?: { __typename?: 'AppealType'; name: string } | null
    } | null> | null
  ) => {
    if (bundles && bundles.length > 0) {
      bundles.map((bundle) => {
        bundle &&
          deleteBundleMutation({
            variables: {
              deleteBundleId: bundle?.id
            },
            // Remove bundle from cache
            update: (cache) => {
              cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'bundle',
                args: { id: bundle.id }
              })
              cache.gc()
            }
          })
      })
    }
  }

  // Display service unavailable if unable to perform bundle deletion
  if (deleteBundleError) {
    throw new Error(deleteBundleError.message)
  }

  /** Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (deleteBundleData && !deleteBundleLoading) {
      closeCreateBundleModal()
    }
  }, [deleteBundleData, deleteBundleLoading, closeCreateBundleModal])

  const steps = [
    <BundleSetupForm key={0} next={handleNextStep} data={data} />,
    <GatherDrsDocumentsForm
      key={1}
      prev={handlePreviousStep}
      next={handleNextStep}
      data={data}
      isSubmitLoading={createBundleLoading}
    />,
    <GatherLocalDocumentsForm
      key={2}
      prev={handlePreviousStep}
      next={handleNextStep}
      data={data}
      isSubmitLoading={createBundleLoading}
    />
  ]

  if (saveBundleError) throw new Error('Unable to get saved bundles.')

  if (savedBundleLoading)
    return (
      <Spinner
        height="50px"
        width="50px"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginTop: '-25px',
          marginLeft: '-25px'
        }}
      />
    )

  if (savedBundleData?.bundles)
    return (
      <Main>
        <ConfirmationPopUp
          title="Are you sure you want to create a new bundle?"
          confirmationMessageText={[
            'You have a saved bundle which has not been completed. If you choose to create a new bundle, your saved bundle will be deleted and all the work will be lost.'
          ]}
          isOpen={isCreateBundleModalOpen}
          onCloseModal={onCloseCreateBundleModal}
          onConfirmationButtonClick={() =>
            openCreateBundle(savedBundleData?.bundles)
          }
          confirmationButtonText="Create a new bundle"
        />
        <div className={styles.container}>
          <div>{steps[currentStep]}</div>
        </div>
      </Main>
    )
  else throw new Error('Unable to get saved bundles.')
}
