import classNames from 'classnames'
import {
  BackLink,
  GridCol,
  GridRow,
  LoadingBox,
  Spinner,
  Tabs
} from 'govuk-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BundleInformationBar } from '../../components/BundleInformationBar'
import { BundlePreviewStatusInformation } from '../../components/BundlePreviewStatusInformation'
import { ConfirmationPopUp } from '../../components/ConfirmationPopUp'
import { DocumentWebViewer } from '../../components/DocumentWebViewer'
import { ErrorSummaryRetry } from '../../components/ErrorSummaryRetry'
import {
  BundleStatus,
  DocumentStatus,
  DossierStatus,
  DossierType,
  KeyEventGenerationStatus,
  useCompleteBundleMutation,
  useDeleteBundleMutation,
  useGetBundleDocumentsStatusByBundleIdQuery,
  useGetBundleInformationQuery,
  useGetDossiersByBundleIdQuery
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { useModal } from '../../hooks/useModal'
import { useRetrieveS3Object } from '../../hooks/useRetrieveS3Object'
import { useWebViewerInstance } from '../../hooks/useWebViewerInstance'
import { TabKeys, TabPanel } from '../../types'
import { formatDossierType } from '../../utils/formatDossierType'
import { getBundleDeletedAt } from '../../utils/getBundleDeletedAt'
import styles from './BundlePreview.module.scss'

const DOSSIER_S3_BUCKET_NAME = process.env.REACT_APP_DOSSIER_S3_BUCKET_NAME
const DOSSIER_KMS_KEY_ID = process.env.REACT_APP_DOSSIER_KMS_KEY_ID
const POLLING_INTERVAL = 5000

const isKeyEventsEnabled = process.env.REACT_APP_ENABLE_KEY_EVENT === 'true'

const disabledElements = [
  'toolbarGroup-View',
  'selectToolButton',
  'toggleNotesButton',
  'menuButton',
  'outlinesPanelButton',
  'leftPanelTabs',
  'thumbnailsPanelButton',
  'thumbnailsSizeSlider',
  'pageNavOverlay',
  'layoutHeader',
  'viewControlsDivider2',
  'singleLayoutButton',
  'doubleLayoutButton',
  'coverLayoutButton',
  'toggleCompareModeButton',
  'viewControlsDivider1',
  'rotateHeader',
  'rotateClockwiseButton',
  'rotateCounterClockwiseButton',
  'contextMenuPopup',
  'formFieldIndicatorContainer',
  'scaleOverlayContainer',
  'formFieldEditPopup',
  'signatureModal',
  'scaleModal',
  'printModal',
  'ColorPickerModal',
  'pageRedactionModal',
  'filterModal',
  'Model3DModal',
  'languageModal',
  'OpenFileModal',
  'linkModal',
  'signatureValidationModal',
  'textPopup',
  'richTextPopup'
]

/** Preview screen displaying up to 4 bundle dossiers in ready only mode. */
export const BundlePreview: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { bundleId } = useParams()
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()

  const webViewerInstance = useWebViewerInstance()

  const {
    isModalOpen: isDeleteBundleModalOpen,
    openModal: openDeleteBundleModal,
    closeModal: closeDeleteBundleModal
  } = useModal()
  const {
    isModalOpen: isCompleteBundleModalOpen,
    openModal: openCompleteBundleModal,
    closeModal: closeCompleteBundleModal
  } = useModal()

  const [isDossierGenerationComplete, setIsDossierGenerationComplete] =
    useState<boolean>(false)

  const [tabIndex, setTabIndex] = useState<number>(0)
  const [tabPanel, setTabPanel] = useState<TabPanel | null>(null)

  const [isDocumentKeyEvent, setIsDocumentKeyEvent] = useState<boolean>(false)

  const {
    retrieveFile,
    fileRetrievedError,
    decryptedFileBuffer,
    fileRetrieved
  } = useRetrieveS3Object()

  const {
    data: bundleDocumentsData,
    error: bundleDocumentsError,
    loading: bundleDocumentsLoading,
    refetch: bundleDocumentsRefetch
  } = useGetBundleDocumentsStatusByBundleIdQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    },
    fetchPolicy: 'cache-and-network'
  })

  const {
    data: bundleInformationData,
    loading: bundleInformationLoading,
    error: bundleInformationError,
    refetch: bundleInformationRefetch
  } = useGetBundleInformationQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    }
  })

  const [
    completeBundleMutation,
    {
      data: completeBundleData,
      loading: completeBundleLoading,
      error: completeBundleError
    }
  ] = useCompleteBundleMutation()

  const [
    deleteBundleMutation,
    { data: deleteBundleData, loading: deleteBundleLoading }
  ] = useDeleteBundleMutation()

  /** Redirect user to dashboard if bundle status is complete after first render.
   *  Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (
      bundleInformationData?.bundle?.status === BundleStatus.Complete &&
      !bundleInformationLoading
    ) {
      navigate('/')
    }
  }, [
    bundleInformationData?.bundle?.status,
    bundleInformationLoading,
    navigate
  ])

  /** Redirect user to bundle editing screen if any of document is not reviewed or dossier not exists.
   *  Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (bundleDocumentsData?.bundle?.status === BundleStatus.InProgress) {
      if (bundleDocumentsData.bundle.documents.length > 0) {
        const isDocumentsNotReviewed =
          bundleDocumentsData?.bundle?.documents.some(
            (document: { status: DocumentStatus }) =>
              document.status !== DocumentStatus.Reviewed
          )

        if (isDocumentsNotReviewed) {
          navigate(`/bundles/${bundleId}`)
        }
      } else {
        navigate(`/bundles/${bundleId}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleDocumentsData])

  // Display service unavailable if unable to invoke complete bundle mutation
  if (completeBundleError) {
    throw new Error(completeBundleError.message)
  }

  /** Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (completeBundleData && !completeBundleLoading) {
      navigate(`/bundles/${bundleId}/complete`)
    }
  }, [bundleId, completeBundleData, completeBundleLoading, navigate])

  /** Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (deleteBundleData && !deleteBundleLoading) {
      navigate('/')
    }
  }, [deleteBundleData, deleteBundleLoading, navigate])

  const {
    data: dossiersData,
    loading: dossiersLoading,
    error: dossiersError,
    refetch: dossierRefetch,
    startPolling: dossiersDataStartPolling,
    stopPolling: dossiersDataStopPolling
  } = useGetDossiersByBundleIdQuery({
    variables: {
      bundleId: bundleId === undefined ? '' : bundleId
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const generatingDossiers = data.bundle.dossiers.filter(
        (doss) => doss.status === DossierStatus.Generating
      )

      if (generatingDossiers.length > 0) {
        dossiersDataStartPolling(POLLING_INTERVAL)
      } else {
        dossiersDataStopPolling()
        bundleInformationRefetch({
          bundleId: bundleId === undefined ? '' : bundleId
        })
      }

      const dossierStatus = data.bundle.dossiers.filter(
        (doss) =>
          doss.status === DossierStatus.Generating ||
          doss.status === DossierStatus.Error
      )
      if (dossierStatus.length === 0) {
        setIsDossierGenerationComplete(true)
      }
    }
  })

  /** Must be wrapped in useEffect as navigation should happen after component render to prevent error:
   * Warning: Cannot update a component (`BrowserRouter`)... */
  useEffect(() => {
    if (!dossiersLoading && dossiersData?.bundle?.dossiers?.length === 0) {
      navigate(`/bundles/${bundleId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!dossiersLoading && dossiersData?.bundle?.dossiers?.length])

  // Update tab panel data when dossier data is loaded
  useEffect(() => {
    if (!dossiersLoading && dossiersData?.bundle?.dossiers) {
      let dossierType: DossierType
      setIsDocumentKeyEvent(false)

      switch (tabIndex) {
        case 1:
          dossierType = DossierType.Redacted
          break
        case 2:
          dossierType = DossierType.Harmful
          break
        case 3:
          dossierType = DossierType.RedactedHarmful
          break
        case 4:
          setIsDocumentKeyEvent(true)
          break
        default:
          dossierType = DossierType.Standard

          break
      }

      // Select dossier type for current tabIndex
      const currentDossierType = dossiersData.bundle.dossiers.filter(
        (document) => document.type === dossierType
      )

      if (currentDossierType.length > 0 && !isDocumentKeyEvent) {
        setTabPanel({
          index: tabIndex,
          key: formatDossierType(currentDossierType[0].type),
          status: currentDossierType[0].status,
          deletedAt: dossiersData?.bundle?.createdAt
            ? getBundleDeletedAt(dossiersData.bundle.createdAt)
            : 'dd/mm/yyyy at hh:mm',
          fileLocation: currentDossierType[0].fileS3ObjectKey
            ? currentDossierType[0].fileS3ObjectKey
            : ''
        })
      } else if (isDocumentKeyEvent) {
        setTabPanel({
          index: tabIndex,
          key: TabKeys[1],
          status:
            bundleInformationData?.bundle.keyEventStatus ===
            KeyEventGenerationStatus.Completed
              ? DossierStatus.Completed
              : DossierStatus.Invalid,
          fileLocation:
            bundleInformationData?.bundle.keyEventStatus ===
              KeyEventGenerationStatus.Completed &&
            bundleInformationData?.bundle.keyEventsPdfFileS3ObjectKey
              ? bundleInformationData?.bundle.keyEventsPdfFileS3ObjectKey
              : ''
        })
      }
    }
  }, [
    dossiersData,
    dossiersLoading,
    tabIndex,
    isDocumentKeyEvent,
    bundleInformationData
  ])

  const downloadAndDecryptDocument = useCallback(
    async (fileLocation: string, bucketName: string, kmsKeyId: string) => {
      await retrieveFile(fileLocation, bucketName, kmsKeyId)
    },
    [retrieveFile]
  )

  // Download and decrypt document when DossierStatus is Completed and file location is available
  useEffect(() => {
    if (!isDocumentKeyEvent) {
      if (
        tabPanel?.status === DossierStatus.Completed &&
        tabPanel?.fileLocation !== ''
      ) {
        downloadAndDecryptDocument(
          tabPanel?.fileLocation,
          DOSSIER_S3_BUCKET_NAME,
          DOSSIER_KMS_KEY_ID
        )
      }
    }
  }, [
    downloadAndDecryptDocument,
    tabPanel?.status,
    tabPanel?.fileLocation,
    isDocumentKeyEvent
  ])

  useEffect(() => {
    if (isDocumentKeyEvent) {
      if (tabPanel?.fileLocation) {
        downloadAndDecryptDocument(
          tabPanel?.fileLocation,
          DOSSIER_S3_BUCKET_NAME,
          DOSSIER_KMS_KEY_ID
        )
      }
    }
  }, [
    downloadAndDecryptDocument,
    tabPanel?.status,
    tabPanel?.fileLocation,
    isDocumentKeyEvent
  ])

  // Load document into viewer when document is retrieved from S3 and customer name is available
  useEffect(() => {
    if (
      webViewerInstance &&
      fileRetrieved &&
      decryptedFileBuffer &&
      tabPanel?.key &&
      dossiersData?.bundle?.customerName
    ) {
      webViewerInstance.UI.loadDocument(
        new Blob([decryptedFileBuffer], {
          type: 'application/pdf'
        }),
        {
          filename: `${tabPanel?.key} - ${dossiersData?.bundle?.customerName} PREVIEW.pdf`
        }
      )
    }
  }, [
    webViewerInstance,
    decryptedFileBuffer,
    fileRetrieved,
    tabPanel?.key,
    dossiersData?.bundle?.customerName,
    isDocumentKeyEvent
  ])

  // Load document into viewer when document is retrieved from S3 and customer name is available

  useEffect(() => {
    if (bundleInformationError) {
      addGraphQlError('bundleInformationError', 'Document not found')
    }
    if (dossiersError) {
      addGraphQlError('dossiersError', 'Unable to retrieve dossier')
    }
    if (bundleDocumentsError) {
      addGraphQlError('bundleDocumentError', 'Bundle Documents not found')
    }
  }, [
    bundleInformationError,
    dossiersError,
    bundleDocumentsError,
    addGraphQlError
  ])

  const handleDiscardBundleConfirmation = () => {
    closeDeleteBundleModal()
    deleteBundleMutation({
      variables: {
        deleteBundleId: bundleId ? bundleId : ''
      },
      // Remove bundle from cache
      update: (cache) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'bundle',
          args: { id: bundleId }
        })
        cache.gc()
      }
    })
  }

  const handleCompleteBundleConfirmation = () => {
    closeCompleteBundleModal()
    completeBundleMutation({
      variables: {
        completeBundleId: bundleId ? bundleId : ''
      }
    })
  }

  const handleBackButtonClick: () => void = () => {
    navigate(`/bundles/${bundleId}`)
  }

  const handleRetryClick = useCallback(() => {
    if (bundleInformationError) {
      bundleInformationRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
    if (dossiersError) {
      dossierRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
    if (bundleDocumentsError) {
      bundleDocumentsRefetch({
        bundleId: bundleId === undefined ? '' : bundleId
      })
    }
  }, [
    bundleId,
    bundleInformationError,
    bundleInformationRefetch,
    dossiersError,
    dossierRefetch,
    bundleDocumentsError,
    bundleDocumentsRefetch
  ])

  const handleOnTabChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number
  ) => {
    if (index !== tabIndex) {
      const mql = window.matchMedia('(min-width: 641px)')
      if (mql.matches) {
        e.preventDefault()
      }

      setTabIndex(index)
      setTabPanel(null)
    }
  }

  if (bundleInformationLoading || bundleDocumentsLoading)
    return (
      <Spinner
        height="50px"
        width="50px"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginTop: '-25px',
          marginLeft: '-25px'
        }}
      />
    )

  // Error handling
  if (graphQlErrors.length > 0) {
    return (
      <>
        <ErrorSummaryRetry
          heading="There was a problem with the service"
          errors={graphQlErrors}
          onHandleErrorClick={handleRetryClick}
          includeBorder={false}
        />
      </>
    )
  }

  return (
    <>
      <BundleInformationBar
        backButtonText="Delete bundle"
        customerName={
          bundleInformationData && bundleInformationData.bundle
            ? bundleInformationData.bundle.customerName
            : ''
        }
        businessArea={
          bundleInformationData && bundleInformationData.bundle
            ? bundleInformationData.bundle.businessArea.name
            : ''
        }
        appealType={
          bundleInformationData &&
          bundleInformationData.bundle &&
          bundleInformationData.bundle.appealType
            ? bundleInformationData.bundle.appealType.name
            : ''
        }
        proceedButtonText="Complete bundle"
        onBackButtonClick={openDeleteBundleModal}
        onProceedButtonClick={openCompleteBundleModal}
        isProceedButtonDisabled={!isDossierGenerationComplete}
        isTooltipEnabled={!isDossierGenerationComplete}
        tooltipText={'All bundles must be generated'}
      />
      <ConfirmationPopUp
        confirmationButtonText="Delete bundle"
        title="Are you sure you want to delete this bundle?"
        confirmationMessageText={[
          'By deleting your bundle all documents and changes made so far will be permanently deleted.'
        ]}
        isOpen={isDeleteBundleModalOpen}
        onCloseModal={closeDeleteBundleModal}
        onConfirmationButtonClick={handleDiscardBundleConfirmation}
      />
      <ConfirmationPopUp
        confirmationButtonText="Complete bundle"
        title="Are you sure you want to complete this bundle?"
        confirmationMessageText={[
          'Note that after completing you cannot go back to edit the bundle. If you believe you still need to make changes before completing, click Cancel.',
          `If your bundle is not completed by ${
            bundleInformationData?.bundle?.createdAt
              ? getBundleDeletedAt(bundleInformationData.bundle.createdAt)
              : 'dd/mm/yyyy at hh:mm'
          } it will be deleted.`
        ]}
        isOpen={isCompleteBundleModalOpen}
        onCloseModal={closeCompleteBundleModal}
        onConfirmationButtonClick={handleCompleteBundleConfirmation}
      />

      <div className={styles.container}>
        <GridRow className={styles.tabListRow}>
          <GridCol setWidth="25%">
            <div className={styles.backLink}>
              <BackLink
                title="Back to editing"
                href=""
                onClick={(e) => {
                  e.preventDefault()
                  handleBackButtonClick()
                }}
              >
                Back to editing
              </BackLink>
            </div>
          </GridCol>
          <GridCol className={styles.tabListColumn}>
            <Tabs.List className={styles.tabList}>
              <Tabs.Tab
                className={classNames({
                  [styles.tab]: tabPanel && tabPanel.index === 0
                })}
                aria-label={'Standard'}
                key="#Standard"
                onClick={(event) => handleOnTabChange(event, 0)}
                selected={tabIndex === 0}
                href="#Standard"
              >
                Standard
              </Tabs.Tab>
              <Tabs.Tab
                className={classNames({
                  [styles.tab]: tabPanel && tabPanel.index === 1
                })}
                aria-label={'Redacted'}
                key="#Redacted"
                onClick={(event) => handleOnTabChange(event, 1)}
                selected={tabIndex === 1}
                href="#Redacted"
              >
                Redacted
              </Tabs.Tab>
              <Tabs.Tab
                className={classNames({
                  [styles.tab]: tabPanel && tabPanel.index === 2
                })}
                aria-label={'Harmful'}
                key="#Harmful"
                onClick={(event) => handleOnTabChange(event, 2)}
                selected={tabIndex === 2}
                href="#Harmful"
              >
                Harmful
              </Tabs.Tab>
              <Tabs.Tab
                className={classNames({
                  [styles.tab]: tabPanel && tabPanel.index === 3
                })}
                aria-label={'Redacted and harmful'}
                key="#Redacted and harmful"
                onClick={(event) => handleOnTabChange(event, 3)}
                selected={tabIndex === 3}
                href="#Redacted and harmful"
              >
                Redacted and harmful
              </Tabs.Tab>
              {isKeyEventsEnabled && (
                <Tabs.Tab
                  className={classNames({
                    [styles.tab]: tabPanel && tabPanel.index === 4
                  })}
                  aria-label={'Key event'}
                  key="#Key event"
                  onClick={(event) => handleOnTabChange(event, 4)}
                  selected={tabIndex === 4}
                  href="#Key event"
                >
                  Key event
                </Tabs.Tab>
              )}
            </Tabs.List>
          </GridCol>
          <GridCol setWidth="one-quarter"></GridCol>
        </GridRow>
        {tabPanel && (
          <Tabs.Panel
            className={styles.tabPanel}
            key={tabPanel.key}
            selected={tabIndex === tabPanel.index}
            id={tabPanel.key}
          >
            <LoadingBox
              loading={
                !dossiersLoading &&
                tabPanel.status === DossierStatus.Completed &&
                !fileRetrieved &&
                fileRetrievedError === ''
              }
            >
              {fileRetrievedError === '' &&
                tabPanel.status === DossierStatus.Completed && (
                  <DocumentWebViewer
                    isReadOnly={true}
                    disabledElements={disabledElements}
                  />
                )}

              <BundlePreviewStatusInformation
                errorRetrievedDocument={fileRetrievedError !== ''}
                name={tabPanel.key}
                status={
                  tabPanel.status ? tabPanel.status : DossierStatus.Completed
                }
                deletedAt={tabPanel.deletedAt ? tabPanel.deletedAt : ''}
              />
            </LoadingBox>
          </Tabs.Panel>
        )}
      </div>
    </>
  )
}
